import { Typography, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const OrtracImportInstructions = () => {
  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Ladda ner och använd{' '}
          <Link
            href="https://www.mozilla.org/sv-SE/firefox/new/"
            target="_blank"
          >
            Firefox
          </Link>{' '}
          (krävs endast för att hämta frågorna, inte för att använda Tentamera).
        </Typography>
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Installera tillägget{' '}
          <Link
            href="https://addons.mozilla.org/sv-SE/firefox/addon/exam-parser/"
            target="_blank"
          >
            Exam Parser
          </Link>{' '}
          i Firefox.
        </Typography>
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Logga in på{' '}
          <Link href="https://lu.ortrac.com/" target="_blank">
            Ortrac
          </Link>
          .
        </Typography>
      </li>
      <li>
        <Typography>
          Gå till "Quizzes" på Ortrac och välj ett quiz med frågor som du vill
          importera.
        </Typography>
      </li>
      <li>
        <Typography>
          Klicka på "Start quiz" och sedan "Finish quiz" direkt.
        </Typography>
      </li>
      <li>
        <Typography gutterBottom>
          På resultatet, klicka på ikonen för tillägget Exam Parser uppe i
          webbläsarens meny till höger.
        </Typography>
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path d="M17.825 24h-15.825v-24h10.189c3.162 0 9.811 7.223 9.811 9.614v10.071l-2-2v-7.228c0-4.107-6-2.457-6-2.457s1.517-6-2.638-6h-7.362v20h11.825l2 2zm-2.026-4.858c-.799.542-1.762.858-2.799.858-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1-.294 1.932-.801 2.714l4.801 4.872-1.414 1.414-4.787-4.858zm-2.799-7.142c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3z" />
        </svg>
      </li>
      <li>
        <Typography>Kopiera koden som visas.</Typography>
      </li>
      <li>
        <Typography>
          Gå till{' '}
          <Link component={RouterLink} to="/import">
            /import
          </Link>{' '}
          på denna sida och klistra in koden i textrutan.
        </Typography>
      </li>
      <li>
        <Typography>Klicka på Import.</Typography>
      </li>
    </ol>
  )
}

export default OrtracImportInstructions
